import React, { Component } from "react";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import config from "../config.json";

class Carousel extends Component {
    state = {
        selectedImageIndex: 0,
    };
    render(){
        const {bannerImages,
               sliderImg
        }= this.props;
    return(
        <AliceCarousel
              autoPlayInterval="5000"
              infinite="true"
              disableButtonsControls="true"
              animationType="fadeout"
              autoHeight={true}
              disableDotsControls={false}
              autoPlay={true}
              onSlideChanged={(item) => {
                this.setState({ selectedImageIndex: item.slide });
              }}
              renderDotsItem={(e) => {
                return (
                  <div
                    style={{
                      position: "relative",
                      margin: "10px",
                      bottom: 
                      sliderImg === "sliderimg-mobile"
                        ? "30px"
                        : "100px",
                      height: "10px",
                      width: "10px",
                      backgroundColor:
                        this.state.selectedImageIndex === e.activeIndex
                          ? "#ff1744"
                          : "#ff8a80",
                      borderRadius: "50%",
                      display: "inlineBlock",
                    }}
                  ></div>
                );
              }}
            >
              {bannerImages.map((image, index) => {
                return (
                  <img
                    key={index}
                    height={window.innerHeight}
                    src={config.image_url+image}
                    alt=""
                    className={sliderImg}
                  />
                );
              })}
            </AliceCarousel>
    )
}
}
export default Carousel;