import React, { Component } from "react";
import "./App.css";
import logo from "./images/airtel-logo.jpg";

import PopupModal from "./components/common/popupModal";
import Footer from "./components/footer";
import FormContainer from "./components/formContainer";
// import { Redirect, Switch, Route } from "react-router-dom";
import Summary from "./components/summary";
import PaymentOptions from "./components/paymentOptions";
import httpRequest from "./services/httpServices";
import config from "./config.json";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import qs from "qs";
import queryString from "query-string";
import Header from "./components/header";
import "./components/cardview.css"
import Carousel from "./components/carousel";

config.backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
config.image_url = process.env.REACT_APP_IMAGE_URL;
config.app_url = process.env.REACT_APP_URL

const closebtn = (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"/>
  </svg>
)
class App extends Component {
  state = {
    mobile_number: "",
    recharge_amount: "",
    connectionType: "",
    packages: [],
    paymentTypes: [],
    selected_package: "",
    customer_type: "", //prepadi and postpaid type
    blackout: false,
    bill_details: { ref: "", email: "" }, //after payment
    email_is_valid: false,
    blocked_price_points: {},
    blocked_price_points_ranges: {},
    recomended_price: null,
    price_blocked : false,
    allow_next : false,
    modal: {
      show: false,
      title: "",
      type: "",
      modal_body: ""
    },
    loading_icon: "",
    current_page: "home", //this is the current page (view). it may contain mainly 2 values (home / summary)
    formContainerStatus: 10, //form steps: 10 = display number only, 20: display amount, 30: display packages, 50: display proceed button
    form_errors: {},
    validation_rule: {
      min_amount: 5, //minimum recharge amount
      max_amount: 1000 //maximum recharge amount
    },
    errors: {
      GE0001: "General Error",
      PY0001: "Low amount",
      PY0002: "Large amount",
      PY0003: "Daily amount exceed",
      PY0004: "Daily count exceed",
      PY0005: "Monthly amount exceed",
      PY0006: "Monthly counr exceed",
      PY0007: "With in blackout time",
      PY0008: "Invalid Payment reference",
      PY0009: "No Payment details found",
      PY0010: "Cannot show old date"
    },
    ipg_type: '',
    bannerImages: [],
    bannerImagesMobile: [],
    ReCAPTCHAEnableDisable: "",
    reCaptchaToken: null,
  };

  constructor(props) {
    super(props);
    this.payementInitForm = React.createRef();
    this.email_validation = React.createRef();
    this.right_panel = React.createRef();
  }
  setReCaptchaToken = (token) => {
    this.setState({ reCaptchaToken: token });
  }

  componentDidMount() {
    this.getBannerImages();

    console.log(process.env.REACT_APP_BACKEND_API_URL);
    console.log(process.env.REACT_APP_BASE_PATH)
    let params = queryString.parse(window.location.search);
    if (params.amount && params.payment_no) {
      this.setState({
        mobile_number: params.payment_no,
        recharge_amount: params.amount
      })
    }
    if (params.mobilenumber && params.recharge_amount) {
      this.setState({
        mobile_number: params.mobilenumber
      })
      this.httpHandleRealNumberRequest(params.mobilenumber)
    }  
    if (params.ref) {
      this.httpFetchReferenceDetails(params.ref);
    }

    this.setState({ loading_icon: "hideme" });

    // let d = this.paymentPendingModal({refNum: 123});
    // this.omoModal(d);
    // this.successEmailAlert("Payment Pending", <p style={{fontSize:"13px"}}>We are working on your payment! An email confirmation will be sent within 30 minutes.</p>);

  }

  getBannerImages = async () => {
    try {
      let { data } = await httpRequest.get(
        config.backend_api_url +
        config.backend_api_endpoints.bannerImage
      );
      let bannerImages =[] 
      let bannerImagesMobile =[] 
      data.data.forEach((img) => { 
        bannerImages.push(img.img_web)
        bannerImagesMobile.push(img.img_mob)
      });
      this.setState({bannerImages,bannerImagesMobile})
    } catch (e) {
      
    }
  };


  errorAlert = (info, clearUrl) => {
    const d = {
      show: true,
      title: "Oops!",
      type: "error",
      modal_body: (
        <div>
          <p>{info}</p>
          <button
            className="airtel-button"
            style={{ width: "100%" }}
            onClick={() => {
              if (clearUrl) {
                window.location.replace(window.location.href.split('?')[0])
              }
              this.omoModal(false);
            }}
          >
            Close
          </button>
        </div>
      )
    };
    this.omoModal(d);
  };

  successEmailAlert = (title, info) => {
    const d = {
      show: true,
      title: title,
      type: "email_success",
      modal_body: (
        <div>
          {info}
          <button
            className="airtel-button"
            style={{ width: "100%", marginTop: "30px" }}
            onClick={() => {
              this.setState({ loading_icon: "" });
              this.omoModal(false);
              window.location.href = config.app_url;
              return true;
            }}
          >
            Ok
          </button>
        </div>
      )
    };
    this.omoModal(d);
  };

  blackoutVerificationPopup = (payhere) => {
    const d = {
      show: true,
      title: "Wait!",
      type: "warning",
      modal_body: (
        <React.Fragment>
          <p style={{ fontSize: "13px" }}>
            You made a successful payment a few minutes ago. Are you sure you want to do another payment?
            <br /><br />
            (Note :  It may take approximately 5 minutes for this payment to reach your account.)

          </p>

          <div className="row">
            <div className="col-6" style={{ paddingRight: "2px" }}>
              <button
                className="btn btn-danger"
                style={{ width: "100%" }}
                onClick={() => {

                  if (payhere) {
                    this.paymentInitPayhere(true);
                  } else {
                    this.setState({ loading_icon: "" });
                    this.payementInitForm.current.submit();
                  }
                  this.omoModal(false);
                }}
              >
                Yes
              </button>
            </div>
            <div className="col-6" style={{ paddingLeft: "2px" }}>
              <button
                className="btn btn-danger"
                style={{ width: "100%" }}
                onClick={() => {
                  this.omoModal(false);
                }}
              >
                No
              </button>
            </div>
          </div>
        </React.Fragment>
      )
    };
    this.omoModal(d);
  };

  emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  handleEmailChange = event => {
    let bill_details = { ...this.state.bill_details };
    bill_details.email = event.currentTarget.value;
    let email_is_valid = false;

    if (this.emailIsValid(bill_details.email) === false) {
      this.email_validation.current.innerHTML = "Invalid Email Address";
      email_is_valid = false
    } else {
      this.email_validation.current.innerHTML = "";
      email_is_valid = true;
    }

    this.setState({ bill_details, email_is_valid });
  };


  paymentSuccessModal = (data) => {
    const d = {
      show: true,
      title: "Payment Successful",
      type: "success",
      modal_body: (
        <React.Fragment>
          <br />
          <p style={{ fontSize: "13px" }}>You have successfully recharged your account. Enter your email to receive a confirmation</p>
          <p style={{ fontSize: "13px", marginBottom: "25px" }}>Ref. Number: <strong>{data.refNum}</strong></p>

          <input
            type="text"
            className="omo-input"
            style={{
              borderBottom: "1px solid #ccc",
              margin: "0 auto",
              textAlign: "center",
              fontSize: "14px",
              width: "80%"
            }}
            onChange={this.handleEmailChange}
            placeholder="Your Email Address"
          />
          <div className="text-danger input_error_msg" ref={this.email_validation}></div>

          <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col-6" style={{ padding: "3px" }}>
              <button
                className="airtel-button modal-button"
                onClick={() => {
                  if (this.state.email_is_valid === true) {
                    this.httpEmailBill();
                    this.omoModal(false);
                  } else {
                    this.email_validation.current.innerHTML = "Invalid Email Address";
                  }
                }}
              >
                Send Email
              </button>
            </div>
            <div className="col-6" style={{ padding: "3px" }}>
              <button
                className="airtel-button modal-button"
                onClick={() => {
                  this.omoModal(false);
                  if (typeof window !== "undefined") {
                    if (window.fbq != null) {
                      window.fbq('track', 'AddPaymentInfo', { currency: "USD", value: 1 });
                    }
                  }
                  //this.setState({current_page:"home", mobile_number:"", formContainerStatus:10});
                  window.gtag_report_conversion(function () {
                    window.location.reload();
                  });
                }}
              >
                Close
              </button>
            </div>
          </div>
        </React.Fragment>
      )
    };

    return d;
  }

  paymentPendingModal = (data) => {
    const d = {
      show: true,
      title: "Payment Processing",
      type: "error",
      modal_body: (
        <React.Fragment>
          <br />
          <p style={{ fontSize: "13px" }}>Your payment is still in progress. Enter your email address to get the confirmation notice.</p>
          <p style={{ fontSize: "13px", marginBottom: "25px" }}>Ref. Number: <strong>{data.refNum}</strong></p>

          <input
            type="text"
            className="omo-input"
            style={{
              borderBottom: "1px solid #ccc",
              margin: "0 auto",
              textAlign: "center",
              fontSize: "14px",
              width: "80%"
            }}
            onChange={this.handleEmailChange}
            placeholder="Your Email Address"
          />
          <div className="text-danger input_error_msg" ref={this.email_validation}></div>

          <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col-6" style={{ padding: "3px" }}>
              <button
                className="airtel-button modal-button"
                onClick={() => {
                  if (this.state.email_is_valid === true) {
                    this.httpEmailBill_pending();
                    this.omoModal(false);
                  } else {
                    this.email_validation.current.innerHTML = "Invalid Email Address";
                  }
                }}
              >
                Send Email
              </button>
            </div>
            <div className="col-6" style={{ padding: "3px" }}>
              <button
                className="airtel-button modal-button"
                onClick={() => {
                  this.omoModal(false);
                  //this.setState({current_page:"home", mobile_number:"", formContainerStatus:10});
                  window.location.reload();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </React.Fragment>
      )
    };

    return d;
  }

  paymentFailedModal = (data) => {
    const d = {
      show: true,
      title: "Payment Failed",
      type: "error",
      modal_body: (
        <React.Fragment>
          <span style={{ color: '#c34134', fontWeight: 500 }}>{data.error_reason}</span>
          <p style={{ color: '#c34134', fontSize: '13px' }}>{data.description}</p>
          <p style={{ fontSize: "13px" }}><br />Ref. Number: <b>{data.refNum}</b></p>
          <div className="row">
            <div className="col-6" style={{ padding: "3px" }}>
              <button
                className="airtel-button modal-button"
                onClick={() => {
                  window.open(config.links.chat_with_us, '_blank');
                  this.omoModal(false);
                }}
              >
                Chat with us
              </button>
            </div>

            <div className="col-6" style={{ padding: "3px" }}>
              <button
                className="airtel-button modal-button"
                onClick={() => {
                  this.omoModal(false);
                  //this.setState({current_page:"home", mobile_number:"", formContainerStatus:10});
                  window.location.reload();
                }}
              >
                Close
              </button>
            </div>


          </div>
        </React.Fragment>
      )
    };

    return d;
  }

  paymentFailedRetryModal = (data) => {
    const d = {
      show: true,
      title: "Payment Failed",
      type: "error",
      modal_body: (
        <React.Fragment>
          {/* <span style={{ color: '#c34134', fontWeight: 500 }}>{data.error_reason}</span> */}
          <p style={{ fontSize: '13px' }}>{data.popup_msg}</p>

          <div className="row">
            <div className="col-6" style={{ padding: "3px" }}>
              <button
                className="airtel-button modal-button"
                onClick={() => {
                  localStorage.removeItem('recharge_ref')
                  this.payementInitForm.current.submit();
                  this.setState({
                    ipg_type: ''
                  })
                  this.omoModal(false);
                }}
              >
                Yes
              </button>
            </div>
            <div className="col-6" style={{ padding: "3px" }}>
              <button
                className="airtel-button modal-button"
                onClick={() => {
                  this.omoModal(false);
                  //this.setState({current_page:"home", mobile_number:"", formContainerStatus:10});
                  localStorage.removeItem('recharge_ref')
                  window.location.replace(window.location.href.split('?')[0])
                  // window.location.reload();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </React.Fragment>
      )
    };

    return d;
  }



  httpFetchReferenceDetails = async ref => {
    this.setState({ loading_icon: "" });


    // this will make it only load the popup once
    try {
      if ((ref !== "0GE0004" && ref !== "0GE0006") && localStorage.getItem('recharge_ref') === ref) {
        console.log('alreay contain');
        window.location.replace(window.location.href.split('?')[0])
        return false;
      } else {
        localStorage.setItem('recharge_ref', ref);
      }
    } catch (err) {
      console.log(err);
    }

    console.log(ref);


    try {
      let { data } = await httpRequest.get(
        config.backend_api_url +
        config.backend_api_endpoints.getStatus +
        "?req_id=" +
        ref
      );

      console.log(data);

      let bill_details = { ref: "", email: "" };

      if (data.success === true) {
        bill_details.ref = data.data.reqId;
        this.setState({ bill_details });


        let d = false;
        if (data.data.statusCode === config.paymentCode.success) {
          d = this.paymentSuccessModal(data.data);

        } else if (data.data.statusCode === config.paymentCode.pending) {
          //d = this.paymentSuccessModal(data.data);

          //enable the line below if client ask to display pending status
          d = this.paymentPendingModal(data.data);

        } else if (data.data.statusCode === config.paymentCode.fail && data.data.retry) {
          this.setState({
            ipg_type: data.data.ipg_type
          })
          d = this.paymentFailedRetryModal(data.data);
        } else {
          d = this.paymentFailedModal(data.data);
        }
        //d = this.paymentSuccessModal(data.data);
        this.omoModal(d);
      } else {
        if (data.retry) {
          this.setState({
            ipg_type: data.ipg_type
          })
          const d = this.paymentFailedRetryModal(data);
          this.omoModal(d);
        } else {
          this.errorAlert(data.message + ' (' + data.code + ')', true);
        }
      }
    } catch (e) {
      this.errorAlert("Something went wrong. Please try again later.");
    }

    this.setState({ loading_icon: "hideme" });
  };



  httpEmailBill_pending = async () => {
    this.setState({ loading_icon: "" });

    let vals = {
      reqId: this.state.bill_details.ref,
      email: this.state.bill_details.email
    };

    let url = config.backend_api_url + config.backend_api_endpoints.emailBill;

    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: qs.stringify(vals),
      url
    };

    try {
      let m = await httpRequest.axios(options);
      console.log(m);
      if (m.data.success === true) {
        this.successEmailAlert("Payment Pending", <p style={{ fontSize: "13px" }}>We are working on your payment! An email confirmation will be sent within 30 minutes.</p>);
      } else {
        this.errorAlert(m.data.message + " (" + m.data.code + ")");
      }
    } catch (e) {
      this.errorAlert("Something went wrong. Please try again later.");
    }

    this.setState({ loading_icon: "hideme" });
  }




  httpEmailBill = async () => {
    this.setState({ loading_icon: "" });

    let vals = {
      reqId: this.state.bill_details.ref,
      email: this.state.bill_details.email
    };

    let url = config.backend_api_url + config.backend_api_endpoints.emailBill;

    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: qs.stringify(vals),
      url
    };

    try {
      let m = await httpRequest.axios(options);
      console.log(m);
      if (m.data.success === true) {
        this.successEmailAlert("Payment Successful", <p style={{ fontSize: "13px" }}>You have successfully recharged your account. You will receive the confirmation via email shortly.</p>);
      } else {
        this.errorAlert(m.data.message + " (" + m.data.code + ")");
      }
    } catch (e) {
      this.errorAlert("Something went wrong. Please try again later.");
    }

    this.setState({ loading_icon: "hideme" });
  };

  onPay = (payhere) => {
    this.httpSummaryValidateionRequest(payhere);
  }

  httpSummaryValidateionRequest = async (payhere) => {

    this.setState({ loading_icon: "" });

    let vals = {
      paymentNumber: "75" + this.state.mobile_number,
      paymentAmount: this.state.recharge_amount,
      payment_type: payhere ? 1 : 0,
      cux_type: "1"
    };
    let url =
      config.backend_api_url + config.backend_api_endpoints.paymentValidation;

    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: qs.stringify(vals),
      url
    };

    try {
      let m = await httpRequest.axios(options);

      if (m.data.success === true) {
        if (payhere) {
          this.paymentInitPayhere(false);
        } else {
          this.payementInitForm.current.submit();
        }
        return true;
      } else {
        if (m.data.code && m.data.code === config.blackout_code) {
          this.setState({ blackout: true });
          this.blackoutVerificationPopup(payhere);
        } else {
          this.errorAlert(m.data.message + " (" + m.data.code + ")");
        }
      }
    } catch (e) {
      this.errorAlert("Something went wrong. Please try again later.");
    }

    this.setState({ loading_icon: "hideme" });
  };

  getPaymentTypes = async () => {
    this.setState({ loading_icon: "" });

    let params = {
      msisdn: '75' + this.state.mobile_number,
      conn_type: this.state.connectionType
    }
    let paramsString = '?' + qs.stringify(params)
    let url =
      config.backend_api_url + config.backend_api_endpoints.getPaymentTypes + paramsString;

    let { data } = await httpRequest.get(url);
    if (data.success === true) {
      this.setState({
        paymentTypes: data.data
      })
    }


    this.setState({ loading_icon: "hideme" });
  }

  paymentInitPayhere = async (blackout) => {
    this.setState({ loading_icon: "" });

    let vals = {
      paymentNumber: "75" + this.state.mobile_number,
      paymentAmount: this.state.recharge_amount,
      recaptcha_tkn:this.state.reCaptchaToken,
      blackout: blackout,
      cux_type: "1"
    };
    let url =
      config.backend_api_url + config.backend_api_endpoints.paymentPayhereInit;

    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: qs.stringify(vals),
      url
    };

    try {
      let response = await httpRequest.axios(options);
      if (response.data.success === true) {
        this.payhereStartPayment(response.data.data, this.httpFetchReferenceDetails, this.errorAlert);
      } else {
        if (response.data.code && response.data.code === config.blackout_code) {
          this.setState({ blackout: true });
          this.blackoutVerificationPopup(true);
        } else {
          this.errorAlert(response.data.message + " (" + response.data.code + ")");
        }
      }
    } catch (e) {
      this.errorAlert("Something went wrong. Please try again later.");
    }

    this.setState({ loading_icon: "hideme" });
  }

  payhereStartPayment = (paymentData, httpFetchReferenceDetails, errorAlert) => {
    // Called when user completed the payment. It can be a successful payment or failure
    window.payhere.onCompleted = function onCompleted(orderId) {
      //Note: validate the payment and show success or failure page to the customer
      console.log("Payment completed. OrderID:" + orderId);
      httpFetchReferenceDetails(orderId);
    };

    // Called when user closes the payment without completing
    window.payhere.onDismissed = function onDismissed() {
      //Note: Prompt user to pay again or show an error page
      console.log("Payment dismissed");
    };

    // Called when error happens when initializing payment such as invalid parameters
    window.payhere.onError = function onError(error) {
      console.log("Error:" + error);
      errorAlert("Something went wrong. Please try again later.");
    };

    var payment = {
      "sandbox": paymentData.sandbox,
      "merchant_id": paymentData.merchant_id,
      "return_url": paymentData.return_url,
      "cancel_url": paymentData.cancel_url,
      "notify_url": paymentData.notify_url,
      "order_id": paymentData.order_id,
      "items": paymentData.items,
      "amount": paymentData.amount,
      "currency": paymentData.currency,
      "first_name": paymentData.first_name,
      "last_name": paymentData.last_name,
      "email": paymentData.email,
      "phone": paymentData.phone,
      "address": paymentData.address,
      "city": paymentData.city,
      "country": paymentData.country,
      "hash": paymentData.hash
    };

    window.payhere.startPayment(payment);
    const isIE = false || !!document.documentMode;
    let userAgentString = navigator.userAgent;
    let chromeAgent = userAgentString.indexOf("Chrome") > -1;
    // Detect Opera 
    let operaAgent = userAgentString.indexOf("OP") > -1;
    // Discard Chrome since it also matches Opera         
    if ((chromeAgent) && (operaAgent)) chromeAgent = false;

    if (isIE || operaAgent) {
      document.getElementById('ph-container').style.position = 'absolute';
      document.getElementById('ph-container').style.width = '100%';
      document.getElementById('ph-container').style.height = '100%';
      document.getElementById('ph-container').style.top = '0';
    }
  }

  httpHandleRealNumberRequest = async number => {
    let formContainerStatus = 10;
    this.setState({ loading_icon: "" });
    try {
      //TODO:Replace the code with backend API call
      let {data}  = await httpRequest.get(
         config.backend_api_url +
         config.backend_api_endpoints.getPacks +
         "?msisdn=75" +
         number + "&cux_type=1"
       );
    

      if (data.success === true) {
        let packages = [];

        if (data.data.type === 1) {
          packages = data.data.packages;
        }
        const customer_type = data.data.type;
        formContainerStatus = 20;

        let validation_rule = { ...this.state.validation_rule };
        let errors = { ...this.state.errors };
        let ReCAPTCHAEnableDisable = 0;

        if (data.data.configs) {
          validation_rule.min_amount = data.data.configs.min_amount;
          validation_rule.max_amount = data.data.configs.max_amount;
          errors = data.data.configs.errors;
          ReCAPTCHAEnableDisable = data.data.configs.recaptchaEnableDisable;
        }

        this.setState({
          packages,
          customer_type,
          formContainerStatus,
          validation_rule,
          errors,
          blocked_price_points: data.blocked_price_points || {},
          blocked_price_points_ranges : data.blocked_price_points_ranges || {},
          ReCAPTCHAEnableDisable
        });
      } else {
        const form_errors = { mobile_number: data.message };
        this.setState({
          packages: [],
          customer_type: "",
          form_errors,
          formContainerStatus
        });
      }
      this.setState({
        connectionType: data.conn_type
      })
    } catch (e) {
      this.setState({ packages: [], formContainerStatus });
    }
    this.setState({ loading_icon: "hideme" });

    let params = queryString.parse(window.location.search)
    if (params.recharge_amount) {
      this.setState({
        recharge_amount: params.recharge_amount
      })
      this.ValidateAutoFill(params.recharge_amount)
    }
  };

  
  omoModal = data => {
    const modal = { ...this.state.modal };

    if (data === false) {
      modal.show = false;
      modal.title = "";
      modal.type = "";
      modal.modal_body = "";
    } else {
      modal.show = true;
      modal.title = data.title;
      modal.type = data.type;
      modal.modal_body = data.modal_body;
    }

    this.setState({ modal: modal });
  };

  scrollToTarget = (target, containerEl) => {
    // Moved up here for readability:
    var isElement = target && target.nodeType === 1,
      isNumber = Object.prototype.toString.call(target) === '[object Number]';

    if (isElement) {
      containerEl.scrollTop = target.offsetTop;
    } else if (isNumber) {
      containerEl.scrollTop = target;
    } else if (target === 'bottom') {
      containerEl.scrollTop = containerEl.scrollHeight - containerEl.offsetHeight;
    } else if (target === 'top') {
      containerEl.scrollTop = 0;
    }
  }

  render() {
    let summary_display = "hideme";
    let form_display = "hideme";
    let payment_display = "hideme";
    //let payment_display = "";

    let filtered = 
      this.state.packages && this.state.recharge_amount
        ? this.filterpackages("search")
        : this.filterpackages("recommend")
        
    filtered = filtered.length < 1 ? this.filterpackages("recommend") : filtered;

    if (this.state.current_page === "summary") {
      summary_display = "";
    } else if (this.state.current_page === "payment") {
      payment_display = ""
    } else {
      form_display = "";
    }
    
    return (
      <div className="container-fluid">
        <div className={"loading-container " + this.state.loading_icon}>
          <div className="loading-ripple-holder">
            <div className="lds-ripple">
              <div />
              <div />
            </div>
          </div>
        </div>
        <ToastContainer />
        <div className="row">
          <div className="col-lg-5 col-xl-4 d-none d-lg-block" style={{ backgroundColor: "#ee1c25" }} id="left_panel">
            {/* <img
              src={left_banner}
              className="left-img-banner"
              alt="Ad Banner"
            /> */}
            
            <Carousel
              bannerImages={this.state.bannerImages}
              sliderImg="sliderimg"
            />

          </div>

          <div className="col-xs-12 col-lg-7 col-xl-8" id="right_panel" ref={this.right_panel}>
            <div className="row header" >
              <div style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row', width: '100%' }}>
                <div>
                  <div className="col-12">
                    <img src={logo} className="img_logo" alt="Airtel Logo" />
                    <h1 className="welcome">
                      Reload &amp; Payments
                    </h1>
                  </div>
                </div>
                <Header />
              </div>
            </div>
            <div className="row content">
              <div id="right_content_container">
                <FormContainer
                  packages={filtered}
                  selected_package={this.state.selected_package}
                  cardSelection={this.handleCardClick}
                  accountValueChange={this.handleAccountValueChange}
                  mobile_number={this.state.mobile_number}
                  recharge_amount={this.state.recharge_amount}
                  display={form_display}
                  onBtnNext={this.handleNextBtn}
                  currentStep={this.state.formContainerStatus}
                  form_errors={this.state.form_errors}
                  customer_type={this.state.customer_type}
                  openCardView={this.openCardView}
                  recomended_price ={this.state.recomended_price}
                  RecommendBtn_function = {this.handleRecommendBtn}
                  price_blocked = {this.state.price_blocked}
                  allow_next = {this.state.allow_next}
                />

                <Summary
                  display={summary_display}
                  onBtnBack={this.handleBackBtn}
                  mobile_number={this.state.mobile_number}
                  recharge_amount={this.state.recharge_amount}
                  selected={this.state.selected_package}
                  customer_type={this.state.customer_type}
                  onBtnContinue={this.handleNextBtn}
                />

                <PaymentOptions
                  display={payment_display}
                  onBtnBack={this.handleBackBtn}
                  onPay={this.onPay}
                  paymentMethods={this.state.paymentTypes}
                  recaptchaEnableDisable={this.state.ReCAPTCHAEnableDisable}
                  reCaptchaToken={this.setReCaptchaToken}
                />

              </div>
            </div>

            <div className="row">
              <div
                className="col-12 d-lg-none d-xl-none"
                style={{ padding: "0px" , backgroundColor: "#000000" }}
              >
              <div className="alice-carousel-mobile">
                <Carousel 
                  bannerImages={this.state.bannerImagesMobile}
                  sliderImg="sliderimg-mobile"
                />
              </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <form
          action={
            config.backend_api_url + config.backend_api_endpoints.paymentInit
          }
          method="POST"
          ref={this.payementInitForm}
        >
          <input
            type="hidden"
            name="paymentNumber"
            readOnly
            value={"75" + this.state.mobile_number}
          />
          <input
            type="hidden"
            name="paymentAmount"
            readOnly
            value={this.state.recharge_amount}
          />
          <input
            type="hidden"
            name="blackout"
            readOnly
            value={this.state.blackout}
          />
          <input
            type="hidden"
            name="ipg_type"
            readOnly
            value={this.state.ipg_type}
          />
          <input
            type="hidden"
            name="cux_type"
            readOnly
            value={"1"}
          />
          <input
            type="hidden"
            name="recaptcha_token"
            readOnly
            value={this.state.reCaptchaToken}
          />
        </form>
        <PopupModal data={this.state.modal} closeCardViewOutside={this.closeCardViewOutside}/>
      </div>
    );
  }

  handlePaymentValidation = () => {
    this.httpSummaryValidateionRequest();
  };

  numeric_check(num) {
    return /^\d+$/.test(num);
  }
  validateForm = () => {
    const { mobile_number, recharge_amount, form_errors } = this.state;
    let errors = {};

    if (Object.keys(form_errors).length > 0) {
      return false;
    }

    if (this.numeric_check(mobile_number) === false && mobile_number !== "") {
      errors.mobile_number = "Invalid Mobile Number";
    }

    if (this.numeric_check(recharge_amount) === false) {
      errors.recharge_amount = "Invalid Amount";
    }

    this.setState({ form_errors: errors });

    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };

  handleNextBtn = stp => {
    let pg = this.state.current_page;
    if (stp === "home") {
      if (this.state.allow_next){
        pg = "summary";
      }
      else{
        if (this.validateForm() === true) {
          pg = "summary";
        }
      }
    } else if (stp === "summary") {
      this.getPaymentTypes()
      pg = "payment"
    } else {
      pg = "home";
    }

    this.scrollToTarget('top', this.right_panel.current);

    this.setState({ current_page: pg });
  };

  handleBackBtn = stp => {
    let pg = this.state.current_page;
    if (stp === "summary") {
      pg = "home"
    } else if (stp === "payment") {
      pg = "summary";
    }

    this.scrollToTarget('top', this.right_panel.current);

    this.setState({ current_page: pg });
  };

  handleAccountValueChange = event => {
    const n = event.currentTarget.name;
    const v = event.currentTarget.value.replace(/\D/, "");
    let form_errors = {};
    let price_blocked  = false;
    let allow_next = false;
    let recomended_price = null;
    let formContainerStatus = 20;

    //this.setState({ [n]: v });

    if (n === "mobile_number") {
      if (v.length === 7) {
        this.httpHandleRealNumberRequest(v);
      } else {
        if (v === "") {
          form_errors = { mobile_number: "" };
        } else {
          form_errors = { mobile_number: "Invalid Mobile Number" };
        }

        formContainerStatus = 10;
        this.setState({ recharge_amount: "", selected_package: "",recomended_price : null,price_blocked : false});
      }
    }

    if (n === "recharge_amount") {
      if (v < this.state.validation_rule.min_amount) {
        form_errors = { recharge_amount: "Minimum amount is Rs." + this.state.validation_rule.min_amount };
      } else if (v > this.state.validation_rule.max_amount) {
        form_errors = {
          recharge_amount:
            "Maximum amount is Rs." +
            this.state.validation_rule.max_amount
        };
      } else if (this.state.blocked_price_points[Number(v)] !== undefined) {
        form_errors = { recharge_amount: this.state.blocked_price_points[Number(v)]["description"] };
        recomended_price = this.state.blocked_price_points[Number(v)]["next_amount"] || null;
        price_blocked = true;
        allow_next = this.state.blocked_price_points[Number(v)]["allow_next"] === 1 ? true : false ;
        
      }
      else{
        for (let x of this.state.blocked_price_points_ranges) {
          if (parseInt(x.min) <= [Number(v)] && parseInt(x.max) >= [Number(v)]) {
            form_errors = { recharge_amount: x["description"] };
            recomended_price = x["next_amount"] || null;
            price_blocked = true;
            allow_next = x["allow_next"] === 1 ? true : false ;
          }
        }
      }

      if (v === "") {
        form_errors = {};
        recomended_price = null;
        price_blocked = false
      }
      this.setState({ selected_package: "" ,recomended_price, price_blocked,allow_next});
    }

    this.setState({ [n]: v, formContainerStatus, form_errors });
  };

  handleCardClick = card => {
    this.setState({ selected_package: card , recharge_amount: card.price })
    let form_errors = {};
    let recomended_price = null;
    let price_blocked = false;

    if (card.price < this.state.validation_rule.min_amount) {
      form_errors = { recharge_amount: "Minimum amount is Rs." + this.state.validation_rule.min_amount };
    } else if (card.price > this.state.validation_rule.max_amount) {
      form_errors = {
        recharge_amount: "Maximum amount is Rs." +
          this.state.validation_rule.max_amount
      };
    }

    this.setState({ form_errors,recomended_price,price_blocked });
  };

  handleRecommendBtn = () => {
    this.setState({ recharge_amount: this.state.recomended_price, 
                    form_errors: {},
                    recomended_price: null,
                    price_blocked : false,
                    current_page:"summary"});
  };

  filterpackages = (event) => {
    let filtered_Packages = [];

    for (let i of this.state.packages) {
      let tempory = {};
      tempory.category_name = i["category_name"];

      tempory.packs = [];
      for (let k of i["packs"]) {
        if (event === "search") {
          if (k.price.search(this.state.recharge_amount) >= 0) {
            tempory.packs.push(k);
          }
        }
        if (event === "recommend") {
          if(k.recommended === "1"){
            tempory.packs.push(k);
          }
        }
      }
      if (tempory.packs.length !== 0) {
        filtered_Packages.push(tempory);
      }
    }
    return filtered_Packages;
  };

  handleBtnActivate = (stp, rechargeAmount, selectedPackage) => {
    this.setState({
      selected_package: selectedPackage,
      recharge_amount: rechargeAmount
    });
    let form_errors = {};
    let recomended_price = null;
    let price_blocked = false;
    
    if (rechargeAmount < this.state.validation_rule.min_amount) {
      form_errors = { recharge_amount: "Minimum amount is Rs." + this.state.validation_rule.min_amount };
    } else if (rechargeAmount > this.state.validation_rule.max_amount) {
      form_errors = {
        recharge_amount: "Maximum amount is Rs." +
          this.state.validation_rule.max_amount
      };
    }

    this.setState({ form_errors,recomended_price,price_blocked });

    if (Object.keys(form_errors).length > 0){
      this.omoModal(false);
    }else{
      let pg = this.state.current_page;
      if (stp === "home") {
        pg = "summary";
      } else {
        pg = "home";
      }

      this.scrollToTarget("top", this.right_panel.current);

      this.setState({ current_page: pg });
    }
  };

  openCardView = (packageCard,info) => {
    const data = {
      show: true,
      type: "cardview",
      modal_body: (
        <div>
          {info}
          <button
            className="close-btn"
            onClick={() => {
              this.omoModal(false);
              return true;
            }}
          >
          {closebtn}
          </button>
          <button
            className="activate-btn"
            onClick={() => {
              this.omoModal(false);
              this.handleBtnActivate("home",packageCard.price,packageCard);
            }}
          >
          Continue
          </button>
        </div>
      ),
    };
    this.omoModal(data);
  };

  closeCardViewOutside = () => {
    this.omoModal(false);
    return true;
  }

  ValidateAutoFill = (amount) => {
    let form_errors = {}
    if (amount< this.state.validation_rule.min_amount) {
      form_errors = { recharge_amount: "Minimum amount is Rs." + this.state.validation_rule.min_amount };
    }
    else if (amount > this.state.validation_rule.max_amount) {
      form_errors = { recharge_amount: "Maximum amount is Rs." + this.state.validation_rule.max_amount };
    }
    this.setState({form_errors});
  }
}

export default App;
