import React from "react";
import getapp_android from "../images/getapp-android.png";
import getapp_ios from "../images/getapp-ios.png";
import getapp_huawei from '../images/getapp-huawei.png'
import config from "../config.json";

const Footer = () => {
  return (
    <React.Fragment>
      <div
        className="col-12 right_footer"
        style={{ width: "100%", marginRight: "-50px" }}
      >
        <div className="row">
          <div className="col-md-6 order-md-2 footer-sec-2">
            <h6>Get the My Airtel App</h6>
            <a
              href={config.app_links.android}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={getapp_android}
                width="100"
                alt="android play store icon"
              />
            </a>
            &nbsp;
            <a
              href={config.app_links.ios}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={getapp_ios} width="100" alt="ios app store icon" />
            </a>
            &nbsp;
            <a
              href={config.app_links.hms}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={getapp_huawei} width="115" alt="ios app store icon" />
            </a>
          </div>

          <div className="col-md-6 footer-sec-1">
            <a
              className="btn btn-default btn-circle-social"
              href={config.social_media.facebook}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-facebook" />
            </a>
            {/* &nbsp;
            <a
              className="btn btn-default btn-circle-social"
              href={config.social_media.twitter}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-twitter" />
            </a> */}
            &nbsp;
            <a
              className="btn btn-default btn-circle-social"
              href={config.social_media.youtube}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-youtube" />
            </a>
            &nbsp;
            <a
              className="btn btn-default btn-circle-social"
              href={config.social_media.instagram}
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="fa fa-instagram" />
            </a>
            &nbsp;
            <p className="copyright">
            © {new Date().getFullYear()} Airtel Sri Lanka. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
