import React from "react";

const RechargeInput = props => {
  const { label, prefix, name, onChange, value, maxLength, type, itmClass, blocked } = props;
  return (
    <div className={"col-12 " + itmClass}>
      <label>{label}</label>
      <div className="customPrefixInput">
        <span className="bottom-border-wrapper">
          <span className="customPrefix" style={{ color:  blocked? "#ed1c24": "black"}}>{prefix} </span>
          <input
            style={{ color:  blocked? "#ed1c24": "black"}}
            type={type}
            className="omo-input"
            maxLength={maxLength}
            value={value}
            name={name}
            onChange={onChange}
            autoComplete="off"
          />
          <span className="omo-input-vl">
            <i className={(props.error) ? "fa fa-exclamation-triangle text-danger animated rotateIn" : ((value !== "") ? "fa fa-check-circle text-success animated flip delay-0.7s" : "fa")} />
          </span>
          <div className="focus-border"></div>
        </span>
        {props.error && (
          <div className="input_error_msg animated fadeInDown">{props.error}</div>
        )}
      </div>
    </div>
  );
};

export default RechargeInput;
