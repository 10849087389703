import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import img_email_sent from "../../images/new/success.png";
import img_success from "../../images/new/success.png";
import img_error from "../../images/new/Oopz.png";
import img_exclamation from "../../images/new/wait.png";

class PopupModal extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.closeCardViewOutside();
    }
  }

  handleClose = () => {
    return true;
  };

  render() {
    const { show, title, type, modal_body } = this.props.data;

    const title_class =
      type === "error"
        ? "text-danger"
        : type === "success" || type === "email_success"
        ? "text-success"
        : "text-default";
    let imgIcon =
      type === "error"
        ? img_error
        : type === "success"
        ? img_success
        : type === "email_success"
        ? img_email_sent
        : type === "cardview"
        ? null
        : img_exclamation;

    return (
      <>
        <Modal show={show} onHide={this.handleClose} className={type === "cardview"?"popup":null}>
          {/* <Modal.Header closeButton>
              <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header> */}
          <Modal.Body ref={type === "cardview"?this.setWrapperRef:null}>
            <div style={type === "cardview"?null:{ textAlign: "center", padding: "20px 20px 0px 20px" }}>
              {show === false ? (
                ""
              ) : (
                <img
                  src={imgIcon}
                  width="100"
                  alt=""
                  className="animated zoomIn"
                />
              )}

              <h5 className={title_class} style={{ marginTop: "10px" }}>
                {title}
              </h5>
              {modal_body}
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default PopupModal;
