import React, { Component } from "react";
import config from "../config.json";
import back_arrow from '../images/back_arrow.png';


class Summary extends Component {
  state = {};

  replaceText(txt){
    let ret = txt;
    ret = ret.replace(/voice/ig, '');
    ret = ret.replace(/sms/ig, '');
    ret = ret.trim();

    return ret;
  }

  printPackageDetails = details => {
    if(details){
      return details.map((info, k) => {
        return (<span className="sum-data" key={k}>{info.text + " : " + this.replaceText(info.value)}</span>);
      });
    }
    return "";
  };


  render() {
    const {display, onBtnBack, mobile_number, recharge_amount, selected, customer_type, onBtnContinue, retailer} = this.props;
    console.log(this.props)
    return (
  <div className={display} style={{paddingBottom:"20px"}}>
      <div className="row" style={{marginTop:"20px"}}>
        <div className="col-12">
          <button className="summary-back-button" style={{fontSize:"20px",fontWeight: "450", color:"#666", cursor:"pointer"}} onClick={()=>{onBtnBack("summary")}}>
            <img src={back_arrow} alt="Go Back" style={{marginLeft: "0px"}} width="25"/>
            <span>Back</span>
          </button>  
        </div>

        <div className="col-12 summary-container">
          <label className="sum-title">
            {retailer ? 'Retailer\'s Mobile Number' : 'Mobile Number'}
          </label>
          <label className="sum-value">075-{mobile_number}</label>
        </div>

        <div className="col-12">
          <hr style={{ width: "80%" }} align="left" />
        </div>

        
        <div className={ (Object.keys(selected).length > 0) ? "col-12 summary-container" : "col-12 summary-container hideme" } >
          <label className="sum-title">
            {selected.price} Package
          </label>
          <label  className="sum-value">
            { this.printPackageDetails(selected.data) }
          </label>
        </div>

        <div className={ (Object.keys(selected).length > 0) ? "col-12" : "col-12 hideme" } >
          <hr style={{ width: "80%" }} align="left" />
        </div>

        <div className="col-12 summary-container">
          <label className="sum-title">
            {(customer_type === config.account.prepaid) ? "Reload Amount" : "Payment Amount" }
          </label>
          <label className="sum-value">Rs.{recharge_amount}</label>
        </div>

        {this.props.time_msg && <div className="col-12 summary-container" style={{display:'flex', flexDirection: 'row'}}>
          <i className="fa fa-exclamation-triangle text-warning" style={{padding: 3, marginRight: 5}} />
          <label className="sum-title">
            {this.props.time_msg}
          </label>
        </div>}

        

        {/* <div className="col-12" style={{ marginTop: "20px" }}>
          <label style={{ fontSize: "20px" }}>
            Payment Amount
          </label>
          <label style={{ fontSize: "16px", float: "right", paddingRight: "20px", marginTop: "5px"}}>Rs. 10,000</label>
        </div> */}

        <div className="col-12" style={{marginTop:"50px"}}>
          <button id="continue-btn" className="airtel-button" onClick={() => {onBtnContinue('summary')}}>Continue</button>
        </div>
      </div>
      </div>

    );
  }
}

export default Summary;
