import axios from "axios";
/* enable below (1) line to activate sentry */
import * as Sentry from '@sentry/browser';
import {toast} from "react-toastify";


axios.interceptors.response.use(null, error => {
  const expectedError = error.response && error.response.status >= 400 && error;
  /* enable below (1) line to activate sentry */
  Sentry.captureException(error);
  if(expectedError){
    return Promise.reject(error);
  }else{
    toast.error("An unexpected Error occured");
  }
});


export default{
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    axios: axios
}

