import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './animate.css';

import App from './App';
// import RetailerApp from './retailer/App'
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

/* enable below (1) line to activate sentry */
import * as Sentry from '@sentry/browser';



import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

/* enable below (1) line to activate sentry */
Sentry.init({ dsn: "https://53159f8fe56d466aa565199aecfb11ca@sentry.io/1492395" });

ReactDOM.render(
        <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
            <Switch>
                {/* <Route path='/retailer' component={RetailerApp} /> */}
                <Route path='/' component={App} />
            </Switch>
    </BrowserRouter>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
