import React, { Component } from "react";
import Package from "./package";
import RechargeInput from "./common/rechargeInput";
import config from "../config.json";

class FormContainer extends Component {
  state = {
    mobile_number: "",
    recharge_amount: "",
  };
  render() {
    const {
      packages,
      selected_package,
      cardSelection,
      openCardView,
      mobile_number,
      recharge_amount,
      accountValueChange,
      display,
      onBtnNext,
      currentStep,
      form_errors,
      customer_type,
      recomended_price,
      RecommendBtn_function,
      price_blocked,
      allow_next
    } = this.props;
    let steps = {
      step1: "hideme",
      step2: "hideme",
      step3: "hideme",
      step4: "hideme"
    };
    if (currentStep === 10) {
      steps.step1 = "";
    } else if (currentStep === 20) {
      steps.step1 = "";
      steps.step2 = "";
    } else if (currentStep === 30) {
      steps.step1 = "";
      steps.step2 = "";
      steps.step3 = "";
    } else if (currentStep === 50) {
      steps.step1 = "";
      steps.step2 = "";
      steps.step3 = "";
      steps.step4 = "";
    }

    let btn_disabled = false;
    if (Object.keys(form_errors).length > 0 || mobile_number === "" || recharge_amount === "" ) {
      btn_disabled = true;
    }
    if (allow_next){
      btn_disabled = false
    }

    return (
      <React.Fragment>
        <div className={display} style={{ paddingBottom: "20px" }}>
          <div className={"row form-panel " + steps.step1}>
            <RechargeInput
              type="tel"
              label={this.props.retailer ? "Retailer's Mobile Number" : "Mobile Number"}
              prefix="75"
              name="mobile_number"
              maxLength="7"
              value={mobile_number}
              onChange={accountValueChange}
              error={form_errors.mobile_number}
              itmClass=""
              blocked = {false}
            />

            <div className="col-12" style={{ paddingTop: "30px" }}></div>

            <RechargeInput
              label={(customer_type === config.account.prepaid) ? "Reload Amount" : "Payment Amount"}
              type="tel"
              prefix="Rs."
              name="recharge_amount"
              maxLength="5"
              value={recharge_amount}
              onChange={accountValueChange}
              error={form_errors.recharge_amount}
              itmClass={steps.step2}
              blocked = {price_blocked}
            />
            {recomended_price !== null ?
              <button className="recommend-button"
                onClick={RecommendBtn_function}>
                Reload Rs. {recomended_price}
              </button>
              : null
            }
          </div>

          <br />

        <br/>
        <br/>

          <div className={"row " + steps.step2}>
            <div className="col-12">
              {packages.length > 0}

            </div>

            <div className="col-12">
              {packages.map((Category, index) => (
                <div>
                  <br />
                  <p className="package-category">{Category.category_name}</p>
                  <Package key={index}
                    packageList={Category.packs}
                    selected={selected_package}
                    onClick={cardSelection}
                    openCardView={openCardView}
                    categoryName={Category.category_name}
                  />
                </div>
              ))
              }
            </div>
          </div>

          <div className={"sticky " + steps.step2}>
            <div className="row">
              <div className="col-12 mt-20">
                <button
                  id="btn_home_next"
                  disabled={btn_disabled}
                  className="airtel-button"
                  onClick={() => {
                    onBtnNext("home");
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FormContainer;
